<template>
<div class="container mt-3">
    <div class="type">
        <h5>
            <font-awesome-icon :icon="['far', 'eye']" /> {{ props.title }}
        </h5>
    </div>
    <div class="row g-1">
        <div v-for="(company, index) in companies" :key="index" class="col-lg-12 col-md-12 col-sm-12 p-2">
            <CompanyCard :company=company :type="'most_views'"></CompanyCard>
        </div>
    </div>
</div>
</template>

<script setup>
import CompanyCard from "../../views/cards/company_card.vue";
import axiosWrapper from "@/services/axios-wrapper";
import { ref, onMounted, defineProps } from "vue";
 

const props = defineProps({
    filter: {
        type: String,
        default: 'safe'
    },
    title: {
        type: String,
        required: true
    }
})

const companies = ref([]);

onMounted(async () => {
    await getCompanies();
});

async function getCompanies() {
    try {
        const response = await axiosWrapper.get(`/companies/views/list`);
        companies.value = response.result.data;
    } catch (error) {
        console.error("Error fetching data:", error);
    }
}
</script>

<style scoped>

</style>
