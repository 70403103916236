export default {
  "qata3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boycott"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
  "Boycott": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boycott"])},
  "Safe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't Boycott"])},
  "trend_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyptian 100%"])},
  "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
  "Alternatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternatives"])},
  "Alternatives_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sometimes inappropriate results may appear with the alternatives. We are working on improving them as soon as possible"])},
  "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "Non-Boycott": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["don't Boycott"])},
  "home-header-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check "])},
  "home-header-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["products"])},
  "home-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for the product and you will know whether we are boycotting it or not and offer alternatives."])},
  "Egyption Companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Egyptian  100%"])},
  "Copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright Reserved QATA3.COM - Demo V-1.0.0 "])},
  "See All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See All"])},
  "error-404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found 404"])},
  "worng-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may have followed the wrong link"])},
  "Click-her": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click Here"])},
  "B-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Back to Home"])},
  "Egyptia-comp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyptian companies 100%"])},
  "B-comp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boycott these Companies"])},
  "ouer-comp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies we support"])},
  "error-maintanence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site is under maintanence, please try again later."])},
  "product-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry there is no product for the requested barcode"])},
  "search-type-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search in Products"])},
  "search-type-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search in Companies"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "Soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon"])},
  "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon"])},
  "category-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category Products"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "scan_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan QR Code"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "compainesViewsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most viewed companies"])},
  "productsViewsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most viewed products"])},
  "Follow Us On": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow Us On"])},
  "Weve_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We've added"])},
  "new Companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Company  "])},
  "Weve_have": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We Have  "])},
  "_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company  "])},
  "boycott_for_gaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boycott For Gaza  🇵🇸"])},
  "new products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" new products  "])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])}
}