<template>
  <button @click="toggleLanguage" class="btn language-btn rounded-4 me-4 d-lg-block d-md-block d-sm-none d-xs-none">
    {{ currentLang === 'en' ? 'العربية' : 'ENGLISH' }}
  </button>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n'

const {locale} = useI18n({useScope: 'global'})
const store = useStore();

const selectedLanguage = ref('ar');
const currentLang = ref('ar');

const toggleLanguage = () => {
  selectedLanguage.value = selectedLanguage.value === 'ar' ? 'en' : 'ar';
  currentLang.value = selectedLanguage.value;

  localStorage.setItem('lang', selectedLanguage.value);
  setSearchPlaceholder(selectedLanguage.value);
  window.location.reload();
  setDirection(selectedLanguage.value);
};


onMounted(() => {
  const storedLanguage = localStorage.getItem('lang');
  if (storedLanguage) {
    selectedLanguage.value = storedLanguage;
    currentLang.value = storedLanguage
    locale.value = storedLanguage
  } else {
    localStorage.setItem('lang', 'ar');
    locale.value = 'ar'
  }

  setDirection(selectedLanguage.value);
  setSearchPlaceholder(selectedLanguage.value);
});

const setDirection = (lang) => {
  document.documentElement.style.direction = lang === 'ar' ? 'rtl' : 'ltr';
  document.body.style.fontFamily = lang === 'ar' ?  "'Alexandria', sans-serif" : "'Poppins', sans-serif"
};
const setSearchPlaceholder = (lang) => {
  const statistic = lang == 'ar' ?
      'بحث' :
      'Search';

  store.commit('setStatistics', statistic);
};

</script>

<style scoped>
.language-btn {
  color: #0156FF;
  border: 2px solid #0156FF;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 26px 8px 26px;
}

.language-btn:hover {
  color: #0156FF;
  border: 1px solid #0156FF;
}

@media screen and (max-width: 767px) {
  .language-btn {

  }
}
</style>
