<template>
  <div class="container mt-2 pt-2 border-top">
    <div class="row">
     <div v-if="company != null" class="col-lg-4 col-md-4 col-xs-12 col-sm-12 p-2">
         <CompanyCard :company="company" class="border"></CompanyCard>
    </div>
    <div class="col-lg-8 col-md-4 col-xs-12 col-sm-12 pt-4">
    <AppPagination
         class="pt-4"
          v-if="pagination != null && pagination?.last_page > 1"
          :pagination="pagination"
          @paginate="pageChanged"
          :offset="6"
      >
      </AppPagination>
    </div>
    </div>


    <div class="row" v-if="productsLength > 0">
      <div v-for="(product, index) in products" :key="index" class="col-lg-3 col-md-6 col-xs-12 col-sm-12 p-2">
        <ProductCard :product="product" :company="company" ></ProductCard>
      </div>

      <AppPagination
          v-if="pagination != null && pagination?.last_page > 1"
          :pagination="pagination"
          @paginate="pageChanged"
          :offset="6"
      >
      </AppPagination>


    </div>

    <div class="row" v-else>
      <div class="col">
        <div class="col-12 text-center align-items-center mt-4">
          <img  alt="image" src="../assets/images/cover1.png" class="w-100"/>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import InpSearch from "@/components/InpSearch.vue";
import axiosWrapper from "@/services/axios-wrapper";
import ProductCard from './cards/product_card.vue';
import CompanyCard from './cards/company_card.vue';

export default {
  name: "Company-products",
  components: {
    // InpSearch,
    ProductCard,
    CompanyCard
  },
  data() {
    return {
      companyId: 5,
      company: {},
      products: [],
      productsLength: 0,
      pagination: {
        current_page: 1,
      },
    };
  },
  beforeMount() {
    this.companyId = this.$route.params.id;
    this.getCompanyById(this.companyId);
    this.getCompanyProductsById();
  },
  methods: {
    async pageChanged(newPagination) {
      this.pagination.current_page = newPagination.current_page;

      await this.getCompanyById(this.companyId);
      await this.getCompanyProductsById();
    },
    /**
     * this method to get company information by id
     *
     * @param {company Id} id
     */
    async getCompanyById(id) {
      try {
        const response = await axiosWrapper.get(
            "/companies/" + id
        );
        if (response.status) {
          this.company = response.result;
        }
      } catch (error) {
        return;
      }
    },
    /**
     * this method to get company product by id
     *
     * @param {company Id} id
     */
    async getCompanyProductsById() {
      try {
        const response = await axiosWrapper.get(
            "/products?per_page=16&filter[company]=" + this.companyId + '&page=' + this.pagination.current_page
        );
        if (response.status) {
          this.products = response.result.data;
          this.paginate_products = response.result;
          this.productsLength = response.result.meta.total;
          this.pagination = response.result.meta;
        }
      } catch (error) {
        return;
      }
    },
  },
};
</script>


<style scoped>

</style>
  
  
  
  
  
