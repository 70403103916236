<template>
    <div class="container-fluid mt4">
        <div class="home">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-4 col-md-12 ">
                    <h1>  {{ t('error-404') }} </h1>
                    <h1>   </h1>
                    <p>
                         {{ t('worng-link') }}
                        <RouterLink :to="'/'"> {{ t('Click-her') }}  </RouterLink>
                        {{ t('B-home') }}
                    </p>
                </div>
                <div class="col-lg-8 col-md-12 banner">
                    <img  alt="image" src="../assets/images/Design.png"  />
                </div>
            </div>
    
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n({ useScope: 'global' })


</script>