export default {
  "qata3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قاطع"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
  "Soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" قريبًا"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركات"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات"])},
  "Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصنيفات"])},
  "Boycott": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قاطع"])},
  "Safe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تقاطع"])},
  "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد التحقق"])},
  "trend_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصرية 100%"])},
  "Alternatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البدائل"])},
  "Alternatives_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احيانا تظهر نتائج غير مناسبة مع البدائل نعمل على تحسينها فى اقرب وقت"])},
  "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])},
  "Non-Boycott": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تقاطع"])},
  "home-header-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بفحص"])},
  "home-header-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات!"])},
  "Egyption Companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مصرية %100"])},
  "Copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حقوق النشر محفوظة QATA3.COM - نسخة تجريبية V-1.0.0"])},
  "home-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بالبحث عن اي منتج وستعرف هل هو في المقاطعة والبدائل المتاحة"])},
  "See All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شاهد الكل"])},
  "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيقنا سيتم إطلاقه قريبًا"])},
  "error-404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة غير موجودة 404"])},
  "worng-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربما اتبعت رابط خاطيء "])},
  "Click-her": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضغط هنا"])},
  "B-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" للتوجه للصفحة الرئيسية"])},
  "Egyptia-comp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركات مصرية %100"])},
  "B-comp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" قاطع هذه الشركات"])},
  "ouer-comp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تقاطع هذه الشركات"])},
  "error-maintanence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع تحت الصيانة برجاء المحاولة لاحقاً."])},
  "product-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عذراً لا يوجد منتج لهذا الباركود"])},
  "search-type-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث عن منتجات "])},
  "search-type-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث فى الشركات "])},
  "category-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتجات التصنيف"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل التصنيف"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الشركة"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
  "scan_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بمسح باركود المنتج"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتج"])},
  "compainesViewsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركات الاكثر مشاهدة"])},
  "productsViewsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات الاكثر مشاهدة"])},
  "Follow Us On": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعنا على"])},
  "Weve_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد قمنا بإضافة   "])},
  "new products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" منتجًا جديدًا.  "])},
  "new Companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" شركة جديدة  "])},
  "Weve_have": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لدينا  "])},
  "_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركة  "])},
  "boycott_for_gaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قاطع عشان غزة  🇵🇸"])},
  "Barcode Scanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امسح الباركود"])}
}