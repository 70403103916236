<template>
  <div class="container overflow-hidden">
    <h1>Proudact Name</h1>
    <br />
    <div class="row ">
      <div class="col-lg-3 col-md-6 col-xs-12 col-sm-12 p-2" v-for="(caty, c) in oneCaty" :key="c">
      <div class="">
        <router-link :to="'product/'+caty.id"> 
          <img  :src="caty.image" class="card-img-top custom-image img-fluid" :alt="caty.desc" />
        </router-link>
         <div class="card-body d-flex flex-column">
             
              <router-link :to="'product/'+caty.id"> 
                <h6 class="product_title mt-auto">{{ caty.name }}</h6>
              </router-link>
          <h6 class="product_title"><img  alt="image" src="../assets/images/svg/company.svg" /> {{caty?.company?.name}}</h6>
          <div class="boycott">

            <span v-if="caty.status == 'qata3'"><img  alt="image" src="../assets/images/svg/slash.svg"   />
              {{ t('Boycott') }} - <small>{{caty?.company?.country}}</small></span>
       
            <span  v-if="caty.status == 'safe'"><img  alt="image" src="../assets/images/verify.png"   />
              {{ t('Safe') }} - <small>{{caty?.company?.country}}</small></span>
       
            <span  v-if="caty.status == 'pending'"><i class="fa-solid fa-clock-rotate-left"></i>
              {{ t('Pending') }}  - <small>{{caty?.company?.country}}</small></span>
       
      
          </div> 
        </div>
       </div>
      </div>

    </div>
  </div>
</template>


<script setup>
import axiosWrapper from "@/services/axios-wrapper";
import { ref, onMounted } from "vue";
import { useI18n } from 'vue-i18n'
const { t } = useI18n({ useScope: 'global' })
const oneCaty = ref([]);
const id = ref(1); // The value must be taken from router orams of the page coming from

const getBoycottCompanies = async () => {
  try {
    let Burl = "https://api.qata3.com/api/v1";
    let url = `${Burl}/products?filter[department]=${id.value}`;
    const result = await axiosWrapper.get(url);
    oneCaty.value = result.result.data;
  } catch (error) {
    return;
  }
};

onMounted(() => {
  getBoycottCompanies();
});
</script>
<style scoped>
.product-img {
  width: 13rem;
  height: 13rem;
}
.card{
  width: 13rem;
}
.cover {
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%) , lightgray 50% / cover no-repeat; */
  background-color: rgba(128, 128, 128, 0.425);
  width: 11.4rem;
  height: 13rem;
  position: absolute;
  margin-right: 2rem !important;
  z-index: 5;

}

.eye-icon {
  margin-top: 90px;
  margin-left: auto;
  margin-right: auto;
  font-size: 25px;
  color: #e6e4e4;
  padding: 10px;
  background-color: #ffffff41;
  border-radius: 30px;
}

.status-icon {
  width: 13%;
  margin-bottom: 15px;
}

@media (max-width: 480px) {
  img {
    border: none;
  }
}
</style>
