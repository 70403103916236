import { createRouter, createWebHistory } from "vue-router";
import i18n from '../i18n';
import CategoryList from "../views/CategoryList.vue";
import CategoryProducts from "../views/CategoryProducts.vue";
import Companies from "../views/Companies.vue";
import ErrorPage from "../views/Error404Page.vue";
import HomeView from "../views/HomeView.vue";
import MaintenancePage from "../views/MainenancePage.vue";
import OneCategory from "../views/OneCategory.vue";
import ProductList from "../views/ProductList.vue";
import Search from "../views/SearchBarcodeResult.vue";
import ViewCompany from "../views/ViewCompany.vue";
import ViewOneProduct from "../views/ViewOneProduct.vue";
const { t } = i18n.global

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: 'قاطع - Boycott'//t("qata3"),
    },
  },
  {
    path: "/product/:id",
    name: "ViewOneProduct",
    component: ViewOneProduct,
    meta: {
      title: t("product"),
    },
  },
  {
    path: "/search",
    name: "SearchProduct",
    component: Search,
    meta: {
      title: t("search"),
    },
  },
  // Companyp roducts Page
  {
    path: "/companies",
    name: "Companies",
    component: Companies,
    meta: {
      title: t("companies"),
    },
  },
  {
    path: "/company/:id",
    name: "Company",
    component: ViewCompany,
    meta: {
      title: t("company"),
    },
  },
  // One Category Page
  {
    path: "/one-category",
    name: "OneCategory",
    component: OneCategory,
    meta: {
      title: t("category"), //Product Name*******////////////
    },
  },
  {
    path: "/products",
    name: "products",
    component: ProductList,
    meta: {
      title: t("products"),
    },
  },
  {
    path: "/category/:id",
    name: "category-products",
    component: CategoryProducts,
    meta: {
      title: t("category-products"),
    },
  },
  {
    path: "/categories",
    name: "categories",
    component: CategoryList,
    meta: {
      title: t("categories"),
    },
  },
  // Error 404
  {
    path: "/:catchAll(.*)",
    name: "ErrorPage",
    component: ErrorPage,
  },
  {
    path: "/Maintenance",
    name: "Maintenance",
    component: MaintenancePage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
});


export default router;
