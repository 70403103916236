<template>
 <div class="container mt-3" v-if="productCount > 0">
        <div class="type border-bottom">
            <h5><font-awesome-icon :icon="['far', 'eye']" /> {{ props.title }} </h5>
        </div>
       <div class="row g-2"  v-for="product in allProducts" :key="product.id">
         <ViewProduct class="mt-1" :product="product"></ViewProduct>
      </div>
  </div>
</template>

<script setup>
import axiosWrapper from "@/services/axios-wrapper";
import { ref, onBeforeMount, defineProps } from "vue";
import ViewProduct from "../../views/cards/product_card.vue";
//import { useRoute } from 'vue-router'

//import { useI18n } from 'vue-i18n'
//const { t } = useI18n({ useScope: 'global' })

//const route = useRoute()
const allProducts = ref([]);
const productCount = ref(1);

 const props = defineProps({
    title: {
        type: String,
        required: true
    }
})

onBeforeMount(() => {


  getProducts();
})


const getProducts = async () => {
  try {
    const url = `/products/views/list`;
    const response = await axiosWrapper.get(url);

    allProducts.value = response.result.data;
    productCount.value = response.result.data.length;

  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

</script>
