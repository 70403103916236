import { createStore } from "vuex";

export default createStore({
  state: {
    statistics: "Search in over 3,000 companies and more than 50,000 products",
    searchType: "product",
    currentNav: "home",
  },
  getters: {},
  mutations: {
    setStatistics(state, newStatistics) {
      state.statistics = newStatistics;
    },
    setSearchType(state, newSearchType) {
      state.searchType = newSearchType;
    },
    
    setCurrentNav(state, newNav) {
      state.currentNav = newNav;
    },
  },
  actions: {},
  modules: {},
});
