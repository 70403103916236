<template>
  <div class="container mt-2">

    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link" :class="{ active: currentStatus == 'all' }" id="tab1-tab"
           :href="'/products?filter[status]=all'" role="tab" aria-controls="tab1" aria-selected="true">{{ t('All') }}
          <span class="badge bg-primary">{{ currentStatus == 'all' && productCount > 1 ? productCount : '' }}</span>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" :class="{ active: currentStatus == 'qata3' }" id="tab2-tab"
           :href="'/products?filter[status]=qata3'" role="tab" aria-controls="tab2" aria-selected="false">{{
            t('Boycott')
          }}
          <span class="badge bg-danger">{{ currentStatus == 'qata3' && productCount > 1 ? productCount : '' }}</span>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" :class="{ active: currentStatus == 'safe' }" id="tab3-tab"
           :href="'/products?filter[status]=safe'" role="tab" aria-controls="tab3" aria-selected="false">{{
            t('Non-Boycott')
          }}
          <span class="badge bg-success">{{ currentStatus == 'safe' && productCount > 1 ? productCount : '' }}</span>
        </a>
      </li>

      <li class="nav-item" role="presentation">
        <a class="nav-link" :class="{ active: currentStatus == 'pending' }" id="tab4-tab"
           :href="'/products?filter[status]=pending'" role="tab" aria-controls="tab4" aria-selected="false">{{
            t('Pending')
          }}
          <span class="badge bg-warning">{{ currentStatus == 'pending' && productCount > 1 ? productCount : '' }}</span>
        </a>
      </li>

    </ul>
  </div>

  <div class="container overflow-hidden">

    <div class="row py-4" v-if="productCount > 0">
      <div class="col-lg-3 mb-4 col-md-6 col-sm-12 col-xs-12" v-for="product in allProducts" :key="product.id">
        <ProductCard :product="product"></ProductCard>
      </div>
      <div class="col-12">
        <!-- <Bootstrap5Pagination align="center" keepLength="true" limit="-1" show-disabled="true" size="small"
          :data="paginate_products" @pagination-change-page="getProducts" /> -->

        <AppPagination
            v-if="pagination != null && pagination?.last_page > 1"
            :pagination="pagination"
            @paginate="pageChanged"
            :offset="6"
        >
        </AppPagination>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col">
        <div class="col-12 text-center align-items-center mt-4">
          <img  alt="image" src="../assets/images/cover1.png" class="w-100"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axiosWrapper from "@/services/axios-wrapper";
import ProductCard from "./cards/product_card.vue";
import {ref, onBeforeMount} from "vue";
import {useRoute} from 'vue-router'

import {useI18n} from 'vue-i18n'

const {t} = useI18n({useScope: 'global'})

const route = useRoute()
const allProducts = ref([]);
const currentStatus = ref('');
const productCount = ref(1);
const paginate_products = ref([]);
const pagination = ref({
  current_page: 1
});


onBeforeMount(() => {
  currentStatus.value = route.query['filter[status]'] ?? 'all';

  getProducts();
})

const pageChanged = async (newPagination) => {
  pagination.value.current_page = newPagination.current_page;

  await getProducts();
};


const getProducts = async () => {
  try {
    const url = `/products?per_page=16&filter[status]=${currentStatus.value}&page=` + pagination.value.current_page;
    const response = await axiosWrapper.get(url);

    allProducts.value = response.result.data;
    productCount.value = response.result.meta.total;
    pagination.value = response.result.meta;
    paginate_products.value = response.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

</script>
