<template>
 <div class="card widgetCard item-product-card">
          <a class="text-center" :href="'/product/' + product.id">
            <img  alt="image" :src="product?.image" :class="{
            'border-danger': product.status === 'qata3' ,
            'border-success': product.status === 'safe'  && product?.company?.trend == 'no',
            'border-warning': product.status === 'pending',
            'border-primary':product?.company?.trend == 'yes'
            }" class="card-img-top custom-image img-fluid product-image" />
          </a>
          <div class="card-body d-flex flex-column">
            <router-link :to="'product/' + product.id">
              <h6 class="product_title product_title_header mt-auto">{{ product.name }}</h6>
            </router-link>
            <h6 class="product_title mt-1"><img  alt="image" width="20" src="../../assets/images/svg/company.svg"/>
              <a :href="'/company/'+product?.company?.id">
              {{ product?.company?.name }}
              </a>
              </h6>
            <div class="boycott">
             <p v-if="product?.company?.country"><small :class="{
              'text-danger':product.status == 'qata3',
              'text-success':product.status == 'safe' && product?.company?.trend == 'no',
              'text-warning':product.status == 'pending',
              'text-primary':product?.company?.trend == 'yes'
             }
             ">
             <span v-if="product.status == 'safe' && product?.company?.trend == 'yes'">
             <font-awesome-icon :icon="['fas', 'circle-check']"/>
             {{ $t('trend_yes') }}</span>
             <span v-else>
             <font-awesome-icon :icon="['fas', 'earth-americas']" /> : 
             {{ product?.company?.country }}</span>
             </small>             
             </p>

              <span v-if="product.status == 'qata3'"><img  alt="image" width="20" src="../../assets/images/svg/slash.svg"/>
                  {{ $t('Boycott') }} </span>
              <span v-if="product.status == 'safe'"><img  alt="image" width="17" src="../../assets/images/svg/right.svg"/>
                  {{ $t('Safe') }} </span>
              <span v-if="product.status == 'pending'">
              <font-awesome-icon class="ms-1" :icon="['fas', 'clock-rotate-left']" size="x" style="color:orange"
                                              v-if="product.status == 'pending'"/>
                  {{ $t('Pending') }}   </span>
            </div>
          </div>
        </div>
</template>
<script>
import { useI18n } from 'vue-i18n';
export default {
  props: ['product'],
  setup() {
    const { t } = useI18n({ useScope: 'global' });
  
    return { $t: t,image:null };
  },
  data() {
    
    // Your data code, if any
  },
  methods: {
     
  },
}
</script>