<template>
  <div class="mt-5">
    <div class="container">
      <div class="row g-0">
        <div class="company-header d-flex justify-content-between align-items-center">
          <h2>{{ props.title }}</h2>
          <RouterLink :to="'/companies?filter' + filter">
            {{ t('See All') }}
            <font-awesome-icon :icon="arrowIcon" class="i"/>
          </RouterLink>
        </div>
        <div v-for="(company, index) in companies" :key="index" class="col-lg-4 col-md-4 col-sm-12 p-2">
          <CompanyCard :company=company class="border"></CompanyCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CompanyCard from "../../views/cards/company_card.vue";
import axiosWrapper from "@/services/axios-wrapper";
import {ref, onMounted, defineProps, computed} from "vue";
import {useI18n} from 'vue-i18n'

const {locale} = useI18n({useScope: 'global'})
const {t} = useI18n({useScope: "global"});

const arrowIcon = computed(() => {
  return locale.value === 'ar' ? ['fas', 'chevron-left'] : ['fas', 'chevron-right'];
});

const props = defineProps({
  filter: {
    type: String,
    default: 'safe'
  },
  title: {
    type: String,
    required: true
  }
})

const companies = ref([]);

onMounted(async () => {
  await getCompanies();
});

async function getCompanies() {
  try {
    const response = await axiosWrapper.get(`/companies?page=1&per_page=21&filter${props.filter}`);
    companies.value = response.result.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}
</script>


<style scoped>
.company-image-cover {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-image {
  height: 100%;

}

.company-name {

  display: flex;
  align-items: center;
}

.type {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 90%;
  margin: 0 auto;
}

.type a {
  display: block;
  color: #D93736;
  font-size: 20.713px;
  font-weight: 500;
}

.type a .i {
  margin-left: 10px;
}

.type h2 {
  font-size: 25px;
}

.product-company-name {
  font-size: 14px;
}
</style>
  
