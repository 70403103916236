<template>
    <div class="container   mt-4" v-if="product != null">
    
        <div class="row mb-4 ms-2 me-2 g-5">
           
            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 p-0 d-flex flex-grow-1 justify-content-center align-items-center border bg bg-light" :class="product_status">
                <img  alt="image" :src="product.image" class="img-fluid" style="height:250px;width:100%;border-radius: 10px;" />
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 border bg bg-light">
                <div class="row g-3">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="d-flex align-items-start p-2 lg-justify-content-start xl-justify-content-start">
                            <img  alt="image" src="../assets/images/svg/product.svg" style="width: 40px;height: 40px;" />
                            <h6 class="ms-2 product_title d-inline mt-auto">{{ product.name }}</h6>
                        </div>
                    </div>
    
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="d-flex lg-align-items-center lg-justify-content-center p-2">
                            <img  alt="image" src="../assets/images/svg/company.svg" class="d-inline" />
                            <h6 class="ms-2 product_title d-inline mt-auto" v-if="product?.company">
                            <a :href="'/company/' + product?.company?.id">
                            {{product?.company?.name}}
                            </a>
                            </h6>
                        </div>
                    </div>
    
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="d-flex  lg-align-items-end float-lg-end p-2">
                            <img  alt="image" src="../assets/images/svg/slash.svg" class="d-inline" v-if="product.status == 'qata3'" />
                            <h6 class="ms-2 product_title d-inline mt-auto" v-if="product.status == 'qata3'">
                                {{ t('Boycott') }} - {{product?.company?.country}} </h6>
    
                            <img  alt="image" src="../assets/images/verify.png" v-if="product.status == 'safe'" />
                            <h6 class="ms-2 product_title d-inline mt-auto" v-if="product.status == 'safe'">
                                {{ t('Safe') }} - <small>{{product?.company?.country}}</small></h6>
    
                            <font-awesome-icon :icon="['fas', 'clock-rotate-left']" size="2x" style="color:orange" v-if="product.status == 'pending'" />
                            <h6 class="ms-2 product_title d-inline mt-auto" v-if="product.status == 'pending'">
                                {{ t('Pending') }} - <small>{{product?.company?.country}}</small></h6>
                        </div>
                    </div>
    
                </div>
            </div>

         
             <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                <div v-if="gaza != null" class="align-items-center justify-content-center">
                    <img  alt="image" :src="gaza.image" class="img-fluid" style="height:300px;width:100%;border-radius: 10px;" />
                </div>
                <div class="skeleton-loader" style="height:250px;width:100%;border-radius: 10px;" v-if="gaza == null"></div>
    
                <h2 class="text-center" v-if="gaza != null">{{gaza.text}}</h2>
                <div class="skeleton-loader" v-if="gaza == null"></div>
            </div>
            
        </div>
    
    
    
        <div class="row">
            <hr class="divider mt-5" />
        </div>
    
        <div class="row mt-4" v-if="product.status == 'qata3'">
            <div class="col-12">
                <h3 class="h-1">{{ t('Alternatives') }} </h3>
                <p class="text-danger ">{{ t('Alternatives_msg') }}</p>
            </div>
            <div class="col-12">
                <div class="row g-3">
                    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12" v-for="(alternative_product, p) in alternative_products" :key="p">
                        <div class="card widgetCard">
                            <a :href="'/product/'+alternative_product.id">
                <img  alt="image" :src="alternative_product.image" class="card-img-top  custom-image img-fluid"  />
                </a>
                            <div class="card-body d-flex flex-column">
                                <h6 class="product_title mt-auto">{{ alternative_product.name }}</h6>
                                <h6 class="product_title"><img  alt="image" src="../assets/images/svg/company.svg" /> 
                                <a :href="'/company/'+alternative_product?.company?.id">
                                {{alternative_product?.company?.name}}
                                </a>
                                </h6>
                                <div class="boycott">
                                    <span v-if="alternative_product.status == 'qata3'"><img  alt="image" src="../assets/images/svg/slash.svg"   />
                        {{ t('Boycott') }}  - <small>{{alternative_product?.company?.country}}</small></span>
                                    <span v-if="alternative_product.status == 'safe'"><img  alt="image" src="../assets/images/verify.png"   />
                        {{ t('Safe') }}  - <small>{{alternative_product?.company?.country}}</small></span>
                                    <span v-if="alternative_product.status == 'pending'"><i class="fa-solid fa-clock-rotate-left"></i>
                        {{ t('Pending') }}  - <small>{{alternative_product?.company?.country}}</small></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
    
            <div class="col-12 text-center align-items-center mt-4">
                <img  alt="image" src="../assets/images/cover1.png" class="w-100" />
            </div>
        </div>
    </div>
</template>

<!-- ************************************************************************** -->

<script setup>
import { useRoute } from "vue-router";
import axiosWrapper from "@/services/axios-wrapper";
import { ref, onMounted } from "vue";
//import router from "@/router/index";
import { useI18n } from 'vue-i18n'
const { t } = useI18n({ useScope: 'global' })

const route = useRoute();
let product = ref(null);
let gaza = ref(null);
let product_status = ref(null);
let alternative_products = ref([]);
 
const showProducts = async () => {
    try {

        // Get Product By Id 
        const result = await axiosWrapper.get('/products/' +route?.params?.id);
        // Set Product Data
        product.value = result?.result;

        document.title = product.value.name+' - '+product?.value?.company?.name;


        if(product.value.status == 'qata3'){
            product_status.value = 'border-danger border-2';
        }else if(product.value.status == 'safe'){
            product_status.value = 'border-success border-2';
        }else{
            product_status.value = 'border-warning border-2';
        }
    

        // Load Image From GAZA 
        const result_gaza = await axiosWrapper.get('/random/gaza/image');
        gaza.value = result_gaza;

        // Get Alternatives Products 
        //console.log(product.value != null && product.value.status != 'safe');
        if (product.value != null && product.value.status != 'safe') {
            const load_alternatives = await axiosWrapper.get('/alternative/product?name=' + product.value.name);
            alternative_products.value = load_alternatives.result.data;
        }
 
    } catch (error) {
        // console.error("Error fetching data:", error);
    }
};

onMounted(() => {
    showProducts();
    

    
});




</script>

<style scoped>
.status-icon {
    width: 10%;
}

.skeleton-loader {
    width: 100%;
    height: 20px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}
</style>
