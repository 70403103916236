<template>
    <div class="home">
    
        <div class="row m-0">
         <div class="col-md-3 col-xs-12 col-sm-12 border-end">
                <HomeCompaniesViews :title="compainesViewsList" />
                <ProductListViews :title="productsViewsList" />
            </div>
            <div class="col-md-9 col-xs-12 col-sm-12 border-end">
                <HomeCompanies filter="[trend]=yes" :title="EgptiaComps" />
                <HomeCompanies filter="[status]=qata3" :title="boycattComps" />
                <HomeCompanies filter="[status]=safe" :title="standWith" />
                <!-- <HomeCategories /> -->
            </div>
        </div>
    
    
    </div>
</template>

<script setup>
import { ref } from "vue";
//import HomeCategories from "@/components/Home/HomeCategories.vue";
import HomeCompanies from "@/components/Home/HomeCompanies.vue";
import HomeCompaniesViews from "@/components/Home/HomeCompaniesViews.vue";
import ProductListViews from "@/components/Home/ProductListViews.vue";

import { useI18n } from 'vue-i18n'
const { t } = useI18n({ useScope: 'global' })

let EgptiaComps = ref(t('Egyptia-comp'));
let boycattComps = ref(t('B-comp'));
let standWith = ref(t('ouer-comp'));
let compainesViewsList = ref(t('compainesViewsList'));
let productsViewsList = ref(t('productsViewsList'));
</script>

<style scoped>
h1 {
    color: #12161c;
    font-size: 40px;
    font-weight: 500;
    white-space: nowrap;
    letter-spacing: -1px;
    padding-top: 20px;
}

h1 span {
    color: #4caf50;
}

p {
    color: #5a5d61;
    font-size: 23px;
    font-weight: 400;
    letter-spacing: -0.5px;
}

.banner img {
    min-width: 100%;
    max-width: 100%;
}

.row {
    padding-left: 45px;
}

.content>* {
    margin-bottom: 25px;
}

@media screen and (max-width: 884px) {
    .row {
        text-align: center;
        padding: 20px 30px;
    }
    h1 {
        font-size: 32px;
        margin-left: -5px;
    }
    p {
        font-size: 20px;
    }
}

.search .icon .i {
    width: 20px;
    height: 20px;
    color: #d71921;
    cursor: pointer;
}

.search input {
    border: none;
    outline: none;
    padding: 10px 15px;
    flex: 1;
}

::placeholder {
    color: #5a5d61;
    font-size: 12px;
    font-weight: 400;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.scan {
    width: 30px;
    height: 30px;
    cursor: pointer;
    flex-shrink: 0;
}

.search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e0dddd;
    padding: 0 10px;
    border-radius: 10px;
}

@media screen and (max-width: 767px) {
    .search .icon .i {
        width: 17px;
        height: 17px;
    }
    input {
        max-width: 80%;
        padding-left: 0;
    }
    .search {
        padding: 0px 5px 0 0;
    }
    .scan {
        margin-left: -40px;
    }
}

@media screen and (max-width: 320px) {
    .row {
        padding: 20px 30px 20px 12px;
    }
    p {
        padding-left: 10px;
    }
    .search input {
        max-width: 70%;
    }
    .search {
        margin-left: 5px;
        padding: 0px 5px;
    }
    .icon {
        margin-left: -25px;
    }
    .scan {
        margin-left: -40px;
    }
}

@media screen and (max-width:1114px) {
    .scan {
        margin-left: -50px;
        margin-right: 5px;
    }
}

/* carousel */

.special {
    width: 90%;
    margin: 10px auto;
    height: 331.959px;
    background: #4caf50;
}

.carousel-item {
    width: 100%;
    height: 324.155px;
}

.carousel-item img {
    max-width: 50%;
    max-height: 100%;
}

.carousel-item p {
    color: #fff;
    font-family: Helvetica Neue;
    font-size: 28.013px;
    font-weight: 500;
    line-height: 40.019px;
}

h2 {
    color: #12161C;
    font-size: 33.799px;
    font-weight: 500;
    margin: 50px 0 0 50px;
}

.products h3 {
    color: #12161C;
    text-align: center;
    font-size: 25.349px;
    font-weight: 400;
}

.product-row {
    max-height: 250px;
    overflow: hidden;
}

@media screen and (max-width:991px) {
    .products {
        margin-bottom: 35px;
    }
}

@media screen and (max-width:768px) {
    .product-row {
        max-height: 230px;
    }
}

@media screen and (max-width:428px) {
    .product-row {
        max-height: 430px;
    }
}

@media screen and (max-width:414px) {
    .product-row {
        max-height: 400px;
    }
}

@media screen and (max-width:390px) {
    .product-row {
        max-height: 370px;
    }
}

@media screen and (max-width:393px) {
    .product-row {
        max-height: 380px;
    }
}

@media screen and (max-width:375px) {
    .product-row {
        max-height: 360px;
    }
}

@media screen and (max-width:360px) {
    .product-row {
        max-height: 360px;
    }
}

@media screen and (max-width:320px) {
    .product-row {
        max-height: 330px;
    }
}

.type {
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 90%;
    margin: 0 auto;
}

.type a {
    display: block;
    color: #D93736;
    font-size: 20.713px;
    font-weight: 500;
}

.type a .i {
    margin-left: 10px;
}

.type h2 {
    font-size: 25px;
}
</style>
