<template>
  <!--    <a class="navbar-brand" href="#"></a>-->
  <div class="header-lang-toggle d-flex">
     <span class="d-md-block d-xs-block d-sm-block d-lg-none d-xl-none">
        <LanguageSelector></LanguageSelector>
       </span>
    <button class="navbar-toggler border-none" style="border:none" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarNav">
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 4.5H21V6.5H3V4.5ZM3 11.5H21V13.5H3V11.5ZM3 18.5H21V20.5H3V18.5Z" fill="black"/>
      </svg>

    </button>
  </div>
  <div class="collapse  navbar-collapse p-2  navbar-white bg-white" style="z-index: 999;" id="navbarNav">
    <ul class="navbar-nav">

      <li class="nav-item " :class="store.state.currentNav == '/'? 'active_segment' : ''">
        <router-link to="/" @click="selectSegment('/')" class="nav-link"> {{ t('Home') }}
        </router-link>
      </li>

      <li class="nav-item " :class="store.state.currentNav == 'companies' ? 'active_segment' : ''">
        <router-link to="/companies" @click="selectSegment('companies')" class="nav-link"> {{ t('companies') }}
        </router-link>
      </li>
      <li class="nav-item " :class="store.state.currentNav == 'products' ? 'active_segment' : ''">
        <router-link to="/products" @click="selectSegment('products')" class="nav-link"> {{
            t('products')
          }}
        </router-link>
      </li>
      <li class="nav-item dropdown" v-if="1 == 2">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          الأقسام الفرعية
        </a>
        <ul class="dropdown-menu">
          <li class="nav-item" v-for="category in categories" :key="category.id"
              :class="store.state.currentNav == '/category/' + category.id ? 'active_segment' : ''">
            <router-link :to="'/category/' + category.id" @click="selectSegment('/category/' + category.id)"
                         class="nav-link">
              {{ category.name }}
            </router-link>

          </li>
        </ul>
      </li>

      <!-- <li class="nav-item ">
              <a class="nav-link" href="#">Services</a>
          </li>
          <li class="nav-item ">
              <a class="nav-link" href="#">Contact</a>
          </li> -->


    </ul>
  </div>
  <div class="nav-item     d-none d-lg-block">

    <LanguageSelector></LanguageSelector>

  </div>
</template>

<script setup>
import LanguageSelector from './LanguageSelector.vue'
//import axiosWrapper from "@/services/axios-wrapper";
import {ref, onMounted} from "vue";
import {useI18n} from 'vue-i18n'
import {useStore} from 'vuex';

const {t} = useI18n({useScope: 'global'});
const store = useStore();
const categories = ref([]);

function selectSegment(segment) {
  //console.log(segment);
  store.commit('setCurrentNav', segment);
}

onMounted(async () => {
  await getCategories();
  store.commit('setCurrentNav', window.location.pathname);
});

async function getCategories() {
  try {
    //const response = await axiosWrapper.get("/departments");
    //categories.value = response.result.data;
    // if(categories.value.length > 0){
    store.commit('setCurrentNav', window.location.pathname);

  } catch (error) {
    console.error("Error fetching data:", error);
  }
}


</script>
<style setup>
.navbar-brand, .navbar-toggler {
  border: none;
  border-color: none;
}

.nav-item a,
.active_segment,
.active_segment a,
.active_segment a:hover,
.active_segment a:visited {
  color: #000;
}

.dropdown li a {
  text-align: start;
}

.navbar-nav {
  width: 100%;
}

.nav-item a {
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}
</style>
