<template>
    <div id="reader" width="380px"></div>
</template>

<script setup>
import { Html5QrcodeScanner } from "html5-qrcode";
import { onMounted, defineProps, defineEmits } from "vue";

const props = defineProps({
    qrbox: {
        type: Number,
        default: 250
    },
    fps: {
        type: Number,
        default: 10
    },
})


const emit = defineEmits(['decoded'])

let html5QrcodeScanner = null;

onMounted(() => {
    const config = {
        fps: props.fps,
        qrbox: props.qrbox,
    };

    html5QrcodeScanner = new Html5QrcodeScanner("reader", config);

    html5QrcodeScanner.render(onScanSuccess);

})

const audio = new Audio(require('@/assets/sound/barcode_scanner.mp3'));
function  playSoundBeeb() {
    audio.play();
}

function resetAudio() {
    setTimeout(() => {
 audio.pause(); // Pause the audio
  audio.currentTime = 0; // Reset the playback position to the beginning
}, 1000);
  
}

function onScanSuccess(decodedText, decodedResult) {
    playSoundBeeb();
    emit('decoded', decodedText, decodedResult);
    html5QrcodeScanner.clear();
    resetAudio();
}

// function onScanError(errorMessage) {
//     // handle on error condition, with error message
//     console.log(errorMessage)
// }

</script>

<style>
button.html5-qrcode-element, select.html5-qrcode-element, button.scanapp-button {
    appearance: none;
    background-color: #FAFBFC;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #24292E;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    padding: 6px 16px;
    position: relative;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
    margin-bottom: 5px;
}


</style>