<template>
  <div class=" d-flex align-items-center bg bg-dark line_top">
    <div class="container">
      <div class="row ">
        <div class="col-6 d-flex justify-content-start">
          <div class=" text-white d-flex justify-content-center">
            <small class="text-sm static_head animated-string" style="color:#ACACAC" v-if="string_head_static != null">{{ string_head_static }}</small>
          </div>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <div class="">
            <small class="text-white text-sm mt-1 me-2 ms-2">{{ t('Follow Us On') }} </small>
            <a :href="'https://fb.com/qata3com'" target="_blank">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.1053 2.27661H2.89447C2.55272 2.27661 2.27661 2.55272 2.27661 2.89447V17.1053C2.27661 17.4471 2.55272 17.7232 2.89447 17.7232H17.1053C17.4471 17.7232 17.7232 17.4471 17.7232 17.1053V2.89447C17.7232 2.55272 17.4471 2.27661 17.1053 2.27661ZM15.3213 6.78508H14.0875C13.1201 6.78508 12.9328 7.24462 12.9328 7.92041V9.40907H15.2421L14.9409 11.7396H12.9328V17.7232H10.5251V11.7415H8.51124V9.40907H10.5251V7.69064C10.5251 5.6961 11.7434 4.60905 13.5237 4.60905C14.3771 4.60905 15.1089 4.67276 15.3232 4.70173V6.78508H15.3213Z"
                    fill="white"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="app-header">
      <div class="row">
        <header class="col-12 p-inline-end-0 ">
          <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light mt-16px">
              <router-link to="/">
                <div class="logo mt-sm-0">
                  <img  alt="image" src="../../assets/logo.png">
                </div>
              </router-link>
              <DropdownMenu></DropdownMenu>
            </nav>
          </div>
        </header>
        <div class="col-12 p-inline-end-0 padding-20px">
          <InpSearch></InpSearch>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import InpSearch from '../InpSearch.vue';
import DropdownMenu from '../DropdownMenu.vue';
import {useI18n} from "vue-i18n";
import axiosWrapper from "@/services/axios-wrapper";
import {ref, onMounted} from "vue";
// {{ t("Weve_added") }} 10 {{ t("new products") }} 
const {t} = useI18n({useScope: "global"});
const string_head_static = ref(null);
const string_static_list = ref(null);
let next_string = ref(0);
   

 /*
 new_companies
 new_products
 total_companies
 total_products
 
 */

onMounted( async () => {
    const response = await axiosWrapper.get('statistics');

    if (response) {
      string_static_list.value = response; 
      console.log(string_static_list.value.total_products);
     // searchResults.value = response;
    }

     string_head_static.value = t("boycott_for_gaza");
      setInterval(() => {
        updateString();
      }, 10000);
});

 
  function numberFormatter(num, digits) {

 const symbols = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'k' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'G' },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' }
];

  const numToCheck = Math.abs(num);
  for (let i = symbols.length - 1; i >= 0; i--) {
    if (numToCheck >= symbols[i].value) {
      const newNumber = (num / symbols[i].value).toFixed(digits);
      return `${newNumber}${symbols[i].symbol}`;
    }
  }
  return '0';
}


  function updateString() {
        if(next_string.value == 0 && string_static_list.value.new_companies > 0){
          string_head_static.value = t("Weve_added")+' ('+numberFormatter(string_static_list.value.new_companies,1)+') '+t("new Companies");
          next_string.value = next_string.value+1;
        }else if(next_string.value == 1 && string_static_list.value.new_products > 0){
          string_head_static.value = t("Weve_added")+' ('+numberFormatter(string_static_list.value.new_products,1)+') '+t("new products");
          next_string.value = next_string.value+1;
        }else if(next_string.value == 2){
          string_head_static.value = t("Weve_have")+' ('+numberFormatter(string_static_list.value.total_companies,1)+') '+t("_company");
          next_string.value = next_string.value+1;
        }else if(next_string.value == 3){
          string_head_static.value = t("Weve_have")+' ('+numberFormatter(string_static_list.value.total_products,1)+') '+t("product");
          next_string.value = 0;
        }else{
          next_string.value = next_string.value+1;
        }
    }
//static_head
</script>

<style scoped>
.line_top {
  height: 44px;
}

header {
  height: 90px;
  margin-bottom: 32px;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.padding-20px {
  padding-right: 20px;
}
.logo {
  width: 60px;
  flex-shrink: 0;
  overflow: hidden;
}

.logo img {
  max-width: 100%;
}

.language {
  width: 44px;
  height: 33px;
}

.mt-16px {
  margin-top: 16px;
}
.p-inline-end-0{
  padding-inline-end: 0 !important;
}

@media screen and (max-width: 767px) {
  header {
    padding: 10px 15px 5px 5px;
  }

  .logo {
    width: 60px;
  }
  .padding-20px {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) {
  .logo {
    width: 60px;
  }
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}

.animated-string {
  animation: fadeInOut 10s ease-in-out infinite;
}
</style>
