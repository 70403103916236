<template>
  
  <div class="container mt-1">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          :class="{ active: currentStatus == 'all' || currentStatus == '' }"
          id="tab1-tab"
          :href="'/companies?filter[status]=all'"
          role="tab"
          aria-controls="tab1"
          aria-selected="true"
          >{{ t("All") }}

           <span class="badge bg-primary">{{ currentStatus == 'all'  || currentStatus == '' && companiesLength > 1?companiesLength:''}}</span>
        </a>
      </li>
       <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          :class="{ active: currentStatus == 'yes' }"
          id="tab1-tab"
          :href="'/companies?filter[trend]=yes'"
          role="tab"
          aria-controls="tab1"
          aria-selected="true"
          >{{ t("Egyption Companies") }}

            <span class="badge bg-success">{{ currentStatus == 'yes' && companiesLength > 1?companiesLength:''}}</span>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          :class="{ active: currentStatus == 'qata3' }"
          id="tab2-tab"
          :href="'/companies?filter[status]=qata3'"
          role="tab"
          aria-controls="tab2"
          aria-selected="false"
          >{{ t("Boycott") }}

             <span class="badge bg-danger">{{ currentStatus == 'qata3' && companiesLength > 1?companiesLength:''}}</span>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          :class="{ active: currentStatus == 'safe' }"
          id="tab3-tab"
          :href="'/companies?filter[status]=safe'"
          role="tab"
          aria-controls="tab3"
          aria-selected="false"
        >
          {{ t("Non-Boycott") }}

           <span class="badge bg-success">{{ currentStatus == 'safe' && companiesLength > 1?companiesLength:''}}</span>
        </a>
      </li>

      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          :class="{ active: currentStatus == 'pending' }"
          id="tab4-tab"
          :href="'/companies?filter[status]=pending'"
          role="tab"
          aria-controls="tab4"
          aria-selected="false"
        >
          {{ t("Pending") }}

           <span class="badge bg-warning">{{ currentStatus == 'pending' && companiesLength > 1?companiesLength:''}}</span>
        </a>
      </li>
    </ul>
  </div>
  <div class="container mt-5">
    <div class="row" v-if="companiesLength > 0">
      <div
        v-for="(company, index) in companies"
        :key="index"
        class="col-lg-3 col-md-6 col-xs-12 col-sm-12 p-2"
       >
         <CompanyCard :company=company class="border"></CompanyCard>
      </div>

      <div class="col-12"  >

<AppPagination  
v-if="pagination != null && pagination?.last_page > 1 && companiesLength > 1"
:pagination="pagination"  
@paginate="pageChanged"
:offset="6" 
>
</AppPagination>
 
      </div>
    </div>

    <div class="row" v-else>
      <div class="col">
        <div class="col-12 text-center align-items-center mt-4">
          <img  alt="image" src="../assets/images/cover1.png" class="w-100" />
        </div>
      </div>
    </div>
  </div>
</template>
  
  
<script>
import axiosWrapper from "@/services/axios-wrapper";
 
import { useI18n } from "vue-i18n";
import CompanyCard from "./cards/company_card.vue";
 
export default {
  name: "CompanyList",
  components: {
    CompanyCard 
  },
  setup() {
    const { t } = useI18n({ useScope: "global" });
    return { t };
  },
  data() {
    
    return {
      pagination: {
          current_page: 1,
      },
      filter: "status",
      currentStatus: "",
      trend: "",
      companies: [],
      companiesLength: 0
    };
  },
  beforeMount() {
     

    if(this.$route.query["filter[status]"] != null){
      this.filter = 'status';
      this.currentStatus = this.$route.query["filter[status]"] ?? "all";
    }else if(this.$route.query["filter[trend]"]  == 'yes'){
      this.currentStatus = this.$route.query["filter[trend]"] ?? "all";
      this.filter = 'trend';
    }
 
    this.getCompanies();
    
  },
  methods: {
    async pageChanged(newPagination){
      this.pagination.current_page = newPagination.current_page;

      await this.getCompanies();
    },
    async getCompanies() {
      try {
        const response = await axiosWrapper.get(
          "/companies?per_page=16&filter["+this.filter+"]="+this.currentStatus +
            "&page="+this.pagination.current_page
        );
        if (response.status) {
          this.companies = response.result.data;
          this.paginate_companies = response.result;
          this.companiesLength = response.result.meta.total;
          this.pagination = response.result.meta;
          
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
     
  },
};
</script>
  
  
  
<style scoped>
.product-image {
  max-height: 250px;
}

.proudct-name {
  height: 50px;
  display: flex;
  align-items: center;
}

.no-products-label {
  text-align: center;
  margin-top: 53px;
}
</style>
  
  
  
  
  
  