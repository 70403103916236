<template>
    <div class="container overflow-hidden mt-4 pt-4">
         
         <div class="row justify-content-end" v-if="department_name != null">
            <div class="col-md-3 col-sm-12 col-xs-12">
              <a href="/">{{t('Home')}}</a> / <a href="#">{{department_name}}</a>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12">
                <select v-model="selectedSubdepartment" @change="onSubdepartmentSelected" class="form-select form-select-sm">
                  <option value="">{{ t('All') }}</option>
                  <option v-for="subdepartment in subdepartments" :value="subdepartment.id" :key="subdepartment.id">{{
                  subdepartment.name }}</option>
                </select>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12">
            </div>
        </div>

        <div class="row" v-if="productCount > 0">
            <br />
            <div class="row g-3">
                <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12" v-for="product in products" :key="product.id">
                    <div class="card widgetCard">
                        <router-link :to="'/product/' + product.id">
                            <img  alt="image" :src="product.image" class="card-img-top custom-image img-fluid"  />
                        </router-link>
                        <div class="card-body d-flex flex-column">
    
                            <router-link :to="'/product/' + product.id">
                                <h6 class="product_title mt-auto">{{ product.name }}</h6>
                            </router-link>
                            <h6 class="product_title"><img  alt="image" src="../assets/images/svg/company.svg" /> {{ product?.company?.name }}</h6>
                            <div class="boycott">
                                <span v-if="product.status == 'qata3'"><img  alt="image" src="../assets/images/svg/slash.svg" />
                      {{ t('Boycott') }} - <small>{{ product?.company?.country }}</small></span>
    
                                <span v-if="product.status == 'safe'"><img  alt="image" src="../assets/images/verify.png" />
                      {{ t('Safe') }} - <small>{{ product?.company?.country }}</small></span>
    
                                <span v-if="product.status == 'pending'"><i class="fa-solid fa-clock-rotate-left"></i>
                      {{ t('Pending') }} - <small>{{ product?.company?.country }}</small></span>
                            </div>
                        </div>
                    </div>
                </div>
    
    
                <div class="col-12">
                    <Bootstrap5Pagination align="center" keepLength="true" limit="-1" show-disabled="true" size="small" :data="paginate_products" @pagination-change-page="getProducts" />
                </div>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col">
                <div class="col-12 text-center align-items-center mt-4">
                    <img  alt="image" src="../assets/images/cover1.png" class="w-100" />
                </div>
            </div>
        </div>
    
    </div>
</template>

<script setup>
import axiosWrapper from "@/services/axios-wrapper";
import { ref, onMounted } from "vue";
import { useRoute } from 'vue-router'
import { Bootstrap5Pagination } from 'laravel-vue-pagination';
import { useI18n } from 'vue-i18n'
const { t } = useI18n({ useScope: 'global' })

const route = useRoute()
const id = route.params.id

const products = ref([]);
const productCount = ref(1);
const paginate_products = ref([]);

const subdepartments = ref([])
const department_name = ref(null);
const selectedSubdepartment = ref('');

const endpointUrl = ref('/products?per_page=16');


const getSubdepartments = async () => {
  try {
    const result = await axiosWrapper.get(`/sub/departments?department=${id}`);
    subdepartments.value = result.result.data;
    department_name.value = result.result.data[0].department.name;
     
  } catch (error) {
    console.error("Error fetching subdepartments:", error);
  }
};

const onSubdepartmentSelected = async () => {
  const filter = selectedSubdepartment.value ? `&filter[sub_department]=${selectedSubdepartment.value}` : '';
  endpointUrl.value = `/products?filter[department]=${id}${filter}`;

  await getProducts();
};


onMounted(() => {
  endpointUrl.value = `/products?per_page=16&filter[department]=${id}`;

  getProducts();
  getSubdepartments();
});

const getProducts = async (page = 1) => {
  try {
    const response = await axiosWrapper.get(endpointUrl.value + `&page=${page}`);
    products.value = response.result.data;
    productCount.value = response.result.meta.total;
    paginate_products.value = response.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

</script>