<template>
    <div class="container-fluid mt4">
        <div class="home">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-4 col-md-12 ">
                    <h1> {{ t('error-maintanence') }} </h1>
                </div>
                <div class="col-lg-8 col-md-12 banner">
                    <img  alt="image" src="../assets/images/Design.png"  />
                </div>
            </div>

        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n({ useScope: 'global' })

</script>