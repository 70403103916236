import axios from "axios";
import router from "../router";

const instance = axios.create({
  baseURL: "https://api.qata3.com/api/v1",
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    lang: localStorage.getItem("lang") ?? "ar",
    // "QATA3-X-API-Key": process.env.VUE_APP_API_KEY,
    "QATA3-X-API-Key":
      "fVrvv2v2gzRUlmgdXKZtulI7UXsakoo67fQNXgZGlSh6q52O348A4ozmL80RuRXYsDvuJd7JDvSA93UR3bZxi9TKW7IIaypvb0GVR2XtHvxzpGlC1CI9OfghN2g1Cxv2Ym6pc7Sm7a51oa2b2f3fvdKjn49FstcW",
  },
});

async function makeRequest(method, url, data = null, config = {}) {
  try {
    const response = await instance.request({
      method,
      url,
      data,
      ...config,
    });

    return response.data;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 500 &&
      error.response.status < 600
    ) {
      router.push("/maintenance");
    } else {
      throw error;
    }
  }
}

const axiosWrapper = {
  async get(url, config = {}) {
    return makeRequest("get", url, null, config);
  },
  async post(url, data, config = {}) {
    return makeRequest("post", url, data, config);
  },
  async put(url, data, config = {}) {
    return makeRequest("put", url, data, config);
  },
  async delete(url, config = {}) {
    return makeRequest("delete", url, null, config);
  },
};

export default axiosWrapper;
