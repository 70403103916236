<template>
  
  <AppHeader />
  <router-view :key="$route.fullPath"></router-view>
  <AppFooter />
  
</template>

<script>
import AppHeader from './components/Layouts/AppHeader.vue';
import AppFooter from './components/Layouts/AppFooter.vue';

export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter
  }
}
</script>