<template>
  <a class="item-link" 
    
   :href="'/company/' + company.id">
    <div class="d-flex item-card">
      <div class="item-card-image">
        <a :href="'/company/' + company.id" class="company-image-cover"  >
          <img  alt="image" width="90" height="90" :src="company.image" :class="{
            'border-danger': company.company_state == 'qata3' , 
            'border-success': company.company_state == 'safe' && company.trend != 'yes' , 
            'border-primary': company.trend == 'yes' , 
            'border-warning': company.company_state == 'pending'
            }">
        </a>
      </div>
      <div class="item-card-description d-flex flex-column justify-content-around">
        <bdi>
          <span style="color:#1877F2">
            <font-awesome-icon v-if="company.company_state === 'safe'" class="ms-2" :icon="['fas', 'circle-check']" />
            <font-awesome-icon :size="'xs'" v-if="company.company_state === 'qata3'" class="ms-2" color="#D93736" :icon="['fas', 'x']" />
            
            <span style="font-size:12px" 
             class="ms-1"
            :class="
            {'text-danger': company.company_state == 'qata3'}
            " v-if="company.trend == 'no' && company?.country?.name !== null"> {{ company?.country?.name }} </span>
            <span v-if="company.trend == 'yes'" class="ms-1">{{ $t('trend_yes') }} </span>
          </span>
        </bdi>
        <b>{{ company.name }}</b>
        <div class="product-status">
          <img  alt="image" src="../../assets/images/svg/slash.svg" class="d-inline" v-if="company.company_state === 'qata3'" width="20" />
          <span v-if="company.company_state === 'qata3'">{{ $t("Boycott") }}</span>
          <img  alt="image" v-if="company.company_state == 'safe'" width="20" height="20" src="../../assets/images/svg/right.svg">
          <span v-if="company.company_state == 'safe'">{{ $t("Safe") }}</span>
          <font-awesome-icon :size="'xs'" :icon="['fas', 'clock-rotate-left']" style="color:orange" v-if="company.company_state == 'pending'" />
          <span v-if="company.company_state == 'pending'">{{ $t("Pending") }}</span>
          <p v-if="type == 'most_views'" class="mt-1">
          <font-awesome-icon :icon="['fas', 'book-open-reader']" color="gray" /> <small color="gray">{{ numberFormatter(company.views,1) }}</small>
          </p> 
        </div>
      </div>
    </div>
   
  </a>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  props: ['company','type'],
  setup() {


    // Import the `t` function from useI18n for translations
    const { t } = useI18n({ useScope: 'global' });

    



    // Return the t function directly, making it available in the template
    return { $t: t };
  },
  data() {
    // Your data code, if any
  },
  methods: {


 numberFormatter(num, digits) {

 const symbols = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'k' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'G' },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' }
];

  const numToCheck = Math.abs(num);
  for (let i = symbols.length - 1; i >= 0; i--) {
    if (numToCheck >= symbols[i].value) {
      const newNumber = (num / symbols[i].value).toFixed(digits);
      return `${newNumber}${symbols[i].symbol}`;
    }
  }
  return '0';
}
  },
}
</script>
