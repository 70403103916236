<template>
  <div class="container overflow-hidden mt-4">
    <!-- <InpSearchVue /> -->
    <div v-if="product">
      <div class="row mb-4 g-5">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="">
            <img  alt="image" :src="product.image" class="img-fluid" style="height: 300px; width: 100%; border-radius: 10px" />
          </div>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12">
          <div v-if="gaza != null" class="align-items-center justify-content-center">
            <img  alt="image" :src="gaza.image" class="img-fluid" style="height: 300px; width: 100%; border-radius: 10px" />
          </div>
          <div class="skeleton-loader" style="height: 300px; width: 100%; border-radius: 10px" v-if="gaza == null"></div>

          <h1 class="text-center" v-if="gaza != null">{{ gaza.text }}</h1>
          <div class="skeleton-loader" v-if="gaza == null"></div>
        </div>
      </div>

      <div class="row g-3">
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="d-flex align-items-start p-2 lg-justify-content-start xl-justify-content-start">
            <img  alt="image" src="../assets/images/svg/product.svg" style="width: 40px; height: 40px" />
            <h6 class="ms-2 product_title d-inline mt-auto">
              {{ product.name }}
            </h6>
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="d-flex lg-align-items-center lg-justify-content-center p-2">
            <img  alt="image" src="../assets/images/svg/company.svg" class="d-inline" />
            <h6 class="ms-2 product_title d-inline mt-auto">
              {{ product?.company?.name }}
            </h6>
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="d-flex lg-align-items-end float-lg-end p-2">
            <img  alt="image" src="../assets/images/svg/slash.svg" class="d-inline" v-if="product.status == 'qata3'" />
            <h6 class="ms-2 product_title d-inline mt-auto" v-if="product.status == 'qata3'">
              {{ t('Boycott') }} - {{ product?.company?.country }}
            </h6>

            <img  alt="image" src="../assets/images/verify.png" v-if="product.status == 'safe'" />
            <h6 class="ms-2 product_title d-inline mt-auto" v-if="product.status == 'safe'">
              {{ t('Safe') }} - <small>{{ product?.company?.country }}</small>
            </h6>

            <font-awesome-icon :icon="['fas', 'clock-rotate-left']" size="2x" style="color: orange"
              v-if="product.status == 'pending'" />
            <h6 class="ms-2 product_title d-inline mt-auto" v-if="product.status == 'pending'">
              {{ t('Pending') }} - <small>{{ product?.company?.country }}</small>
            </h6>
          </div>
        </div>
      </div>

      <div class="row">
        <hr class="divider mt-5" />
      </div>

      <div class="row mt-4" v-if="product != null && product.status != 'safe'">
        <div class="col-12">
          <h3 class="h-1">{{ t("Alternatives") }}</h3>
        </div>
        <div class="col-12">
          <div class="row g-3">
            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12" v-for="(alternative_product, p) in alternative_products"
              :key="p">
              <div class="card widgetCard">
                <a :href="'/product/' + alternative_product.id">
                  <img  alt="image" :src="alternative_product.image" class="card-img-top custom-image img-fluid"  />
                </a>
                <div class="card-body d-flex flex-column">
                  <h6 class="product_title mt-auto">
                    {{ alternative_product.name }}
                  </h6>
                  <h6 class="product-company-name">
                    <img  alt="image" src="../assets/images/svg/company.svg" />
                    {{ product?.company?.name }}
                  </h6>
                  <div class="boycott">
                    <span v-if="alternative_product.status == 'qata3'"><img  alt="image" src="../assets/images/svg/slash.svg" />
                      {{ t("Boycott") }} -
                      <small>{{ product?.company?.country }}</small></span>
                    <span v-if="alternative_product.status == 'safe'"><img  alt="image" src="../assets/images/verify.png" />
                      {{ t("Safe") }} -
                      <small>{{ product?.company?.country }}</small></span>
                    <span v-if="alternative_product.status == 'pending'"><i class="fa-solid fa-clock-rotate-left"></i>
                      {{ t("Pending") }} -
                      <small>{{ product?.company?.country }}</small></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div v-else>
      <div class="col-12 text-center align-items-center mt-4">
        <p style="font-size: large;">{{ t("product-not-found") }}</p>
        <img  alt="image" src="../assets/images/cover1.png" class="w-100" />
      </div>
    </div>
  </div>
</template>

<!-- ************************************************************************** -->

<script setup>
//import { useRoute } from "vue-router";
import axiosWrapper from "@/services/axios-wrapper";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";

//import router from "@/router/index";
import { useI18n } from "vue-i18n";
const { t } = useI18n({ useScope: "global" });

let product = ref(null);
let gaza = ref(null);
let alternative_products = ref([]);
let route = useRoute();

const showProduct = async () => {
  try {
    const barcode = route.query["barcode"]; // Get Product By Id
    if (!barcode)
      return;

    const result = await axiosWrapper.get(
      `/products?filter[barcode]=${barcode}`
    );
    product.value = result?.result.data[0];

    // // Set Product Data
    // Load Image From GAZA
    const result_gaza = await axiosWrapper.get("/random/gaza/image");
    gaza.value = result_gaza;
    // Get Alternatives Products
    if (product.value != null && product.value.status != "safe") {
      const load_alternatives = await axiosWrapper.get(
        "/alternative/product?name=" + product.value.name
      );
      alternative_products.value = load_alternatives.result.data;
    }
  } catch (error) {
    // console.error("Error fetching data:", error);
  }
};

onMounted(() => {
  showProduct();
});
</script>

<style scoped>
.status-icon {
  width: 10%;
}

.skeleton-loader {
  width: 100%;
  height: 20px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 2s infinite;
}

.not-found-product-text {
  text-align: center;
  padding: 31px 0;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}
</style>
