import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import PrimeVue from "primevue/config";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import $ from "jquery";
window.$ = window.jQuery = $;

// import css
import { } from "../src/assets/css/main.css";

//importing Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import i18n from "./i18n";

library.add(fas, far, fab);



const app = createApp(App)
  .use(store)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component('AppPagination', require('./components/Pagination.vue').default)
  .use(router)
  .use(PrimeVue);

app.use(i18n);

app.mount("#app");
 