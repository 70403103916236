<template>
  <div class="container padding-22px">
    <div class="row g-1 mb-4 ">
      <div
          class=" padding-14-31 col-lg-2 col-md-4 col-sm-12 col-xs-12 d-flex align-items-center justify-content-center">
        <div class="item-mobile d-flex align-items-center justify-content-around w-100">
          <div class=" small-flexbox">
            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M10 0L19.5 5.5V16.5L10 22L0.5 16.5V5.5L10 0ZM3.49388 6.0777L11.0001 10.4234V19.11L17.5 15.3469V6.65311L10 2.311L3.49388 6.0777ZM2.5 7.81329V15.3469L9.0001 19.1101V11.5765L2.5 7.81329Z"
                  fill="#6E6E9B"/>
            </svg>
          </div>
          <div class=" padding-small-0 w-small-100">
            <select v-model="searchType" class="searchby  ms-2" @change="onChange">
              <option value="product">{{ t("search-type-product") }}</option>
              <option value="company">{{ t("search-type-company") }}</option>
            </select>
          </div>
        </div>
      </div>
      <div
          class=" margin-bottom-38px padding-14-31 col-lg-7 col-md-4 col-sm-12 col-xs-12 d-flex flex-column justify-content-center align-items-center">
        <div class="input-section position-relative row w-100 border-end">
          <div class=" col-lg-12 col-10 padding-small-0">
            <input type="search" :placeholder="searchByText"
                   class="form-control searchinput margin-inline-end-0 padding-small-0"
                   v-model="searchValue" @input="onChangeDebounced"/>
          </div>
          <div class="height-auto col-2 d-large-none d-flex align-items-center justify-content-end">
            <img  alt="image" class="" width="24" src="../assets/images/svg/search.svg">
          </div>
        </div>

        <div v-if="searchResults.length" class="results position-relative">
          <ul class="list-group me-0 zindex-dropdown position-sticky top-0 end-0 p-0">
            <li class="list-group-item" v-for="item in searchResults" :key="item.id" @click="goToItem(item.id)">
              <small class="badge bg-danger" v-if="item.status == 'qata3'">{{ t('Boycott') }}</small>
              <small class="badge bg-success" v-else-if="item.status == 'safe'">{{ t('Safe') }}</small>
              <small class="badge bg-warning" v-else-if="item.status == 'pending'">{{ t('Pending') }}</small> -
              {{ item.name }}
            </li>
          </ul>
        </div>

        <!-- The Modal -->
        <div class="" style="background:#fff">
          <Dialog v-model:visible="visible" modal :header="t('Barcode Scanner')"
                  :style="{ background: '#fff',width:'380px' }">
            <BarcodeScanner :qrbox="200" :fps="10" @decoded="onDecode" style="width: 380px;">
            </BarcodeScanner>
          </Dialog>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 d-flex justify-content-between">
        <div class="height-auto display-none-small d-flex align-items-center">
          <img  alt="image" class="" width="24" src="../assets/images/svg/search.svg">
        </div>
        <button @click="visible = true" class="scan-button btn ">
          {{ t("scan_qr_code") }}
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 16V21H3V16H5V19H19V16H21ZM3 11H21V13H3V11ZM21 8H19V5H5V8H3V3H21V8Z" fill="white"/>
          </svg>


        </button>
      </div>
    </div>

  </div>
</template>

<script setup>
import axiosWrapper from "@/services/axios-wrapper";
import {ref, onMounted, onUnmounted} from "vue";
import Dialog from 'primevue/dialog';
import router from "@/router/index";
import {debounce} from '@/helpers/debounce.js';
import {useStore} from 'vuex';
import BarcodeScanner from "@/components/BarcodeScanner.vue"
import {useI18n} from 'vue-i18n'


const {t} = useI18n({useScope: 'global'})
const searchType = ref('product');


const searchValue = ref('');
const searchByText = ref('');
const decodedText = ref("");
const visible = ref(false);

const store = useStore();
//const statistics = computed(() => store.state.statistics);

const searchResults = ref([]);

function onChange() {
  store.commit('setSearchType', searchType.value);
  searchByText.value = searchType.value == 'product' ? t('search-type-product') : t('search-type-company');

}

const onDecode = (text) => {
  //document.querySelector(".html5-qrcode-element")?.classList?.add("btn")?.classList?.add("btn-primary");
  decodedText.value = text;
  visible.value = false;
  router.push('/search?barcode=' + text);
};


const onChangeDebounced = debounce(() => {
  search();
}, 200);

const search = async () => {
  try {
    if (!searchValue.value) {
      searchResults.value = [];
      return;
    }

    let searchEndpoint = store.state.searchType === "product" ? "/products" : "/companies";
    let endpoint = `${searchEndpoint}?filter[keyword]=${searchValue.value}`;

    const response = await axiosWrapper.get(endpoint);

    if (response.result.data) {
      searchResults.value = response.result.data;
    }
  } catch (error) {
    console.error("An error occurred:", error);
  }
};


const goToItem = (productId) => {
  let path = store.state.searchType === "product" ? "/product" : "/company";

  router.push({path: `${path}/${productId}`});
  searchValue.value = '';
  searchResults.value = [];
};

const handleClickOutside = (event) => {
  const searchElement = document.querySelector('.search');
  if (searchElement && !searchElement.contains(event.target)) {
    searchResults.value = [];
  }
};


onMounted(() => {
  window.addEventListener('click', handleClickOutside);
  searchByText.value = t('search-type-product');

});

onUnmounted(() => {
  window.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped>
.searchby {
  border: none;
}

.searchby {
  width: 99%;
  height: 31px;
  text-align: center;
}

/* Custom arrow styling */

.searchby::after {
  content: '\25BC';
  /* Unicode character for a down arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  /* Ensure the arrow does not interfere with the select box */
}

.height-auto {
  height: auto;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="none"><path d="M17.0306 1.53062L9.53061 9.03061C9.46096 9.10035 9.37824 9.15567 9.2872 9.19341C9.19615 9.23115 9.09855 9.25058 8.99999 9.25058C8.90143 9.25058 8.80383 9.23115 8.71278 9.19341C8.62174 9.15567 8.53902 9.10035 8.46936 9.03061L0.969365 1.53062C0.828634 1.38988 0.749573 1.19901 0.749573 0.99999C0.749573 0.800967 0.828634 0.610095 0.969365 0.469364C1.1101 0.328634 1.30097 0.249573 1.49999 0.249573C1.69901 0.249573 1.88988 0.328634 2.03061 0.469364L8.99999 7.43968L15.9694 0.469364C16.039 0.399682 16.1218 0.344406 16.2128 0.306695C16.3039 0.268983 16.4014 0.249573 16.5 0.249573C16.5985 0.249573 16.6961 0.268983 16.7872 0.306695C16.8782 0.344406 16.9609 0.399682 17.0306 0.469364C17.1003 0.539047 17.1556 0.621773 17.1933 0.712817C17.231 0.803862 17.2504 0.901444 17.2504 0.99999C17.2504 1.09854 17.231 1.19612 17.1933 1.28716C17.1556 1.37821 17.1003 1.46093 17.0306 1.53062Z" fill="%236E6E9B"/></svg>') no-repeat;
  background-position-y: 15px;
  padding-left: 28px;
  font-size: 16px;
  color: #6E6E9B;
}

.input-section img {
  left: 0;
}

.searchinput {
  padding: 16px, 20px, 16px, 20px;
  border: none;
  font-size: 16px;
  color: #181823;
}

.searchinput::-webkit-input-placeholder {
  font-size: 16px;
  color: #181823;
  font-weight: 600;
}


.form-control:focus {
  outline: 0 none !important;
  border: none inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

select:focus {
  outline: 0 none !important;
  border: none inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}


.results {
  width: 100%;
  overflow: auto;
  height: 200px;
  z-index: 9999;

}

.results ul {
  width: 100%;
}

.results ul li {

  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
}

.html5-qrcode-element {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/*
@media (min-width: 576px) and (max-width: 767.98px) {
Additional styles for small screens

}
*/

.scan-button {
  width: auto;
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 54px;
  background: #537FE7;
  color: #fff;
  height: fit-content;
}

.btn:hover {
  background-color: #0156FF;
  color: #fff;
}

@media screen and (min-width: 901px) {
  .d-large-none {
    display: none !important;
  }
}
@media screen and (max-width: 1025px) {
  .scan-button {
    padding: 12px 12px;
    font-size: 14px;
  }

  .searchby {
    margin-right: 5px;
  }
}

@media screen and (max-width: 900px) {
  .display-none-small {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {


  .searchby {
    text-align: start;
  }

  .small-flexbox {
    display: flex;
    justify-content: end;
    padding-left: 0;
  }

  .padding-small-0 {
    padding-right: 0;
  }

  /*.searchinput {*/
  /*  margin-right: 25px;*/
  /*}*/
  .w-small-100 {
    width: 100%;
  }

  .scan-button {
    width: 100%;
  }

  .padding-14-31 {
    padding: 0px 31px;
  }

  .item-mobile {
    border-bottom: 1px solid #ddd;
    padding-bottom: 16px;
    margin-bottom: 12px;
  }

  .searchby {
    margin-inline-start: 10px;
    margin-inline-end: 10px;
  }

  .input-section {
    border-right: none !important;
  }

  .margin-inline-end-0 {
    margin-right: 0 !important;
  }

  .margin-bottom-38px {
    margin-bottom: 38px;
  }
}
</style>
